<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="border">
          <div class="modal-body">
            <slot name="body">
              <v-icon color="red">mdi-alert</v-icon>
              {{ message }}
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('close')">Cancel</button>
              <button class="modal-default-button" @click="$emit('reset')">OK</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal',
  data: () => ({
    showModal: false,
  }),
  props: {
    message: {
      type: String,
      default: '',
    },
    reset: {
      type: Function,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  background-color: #000;
  color: #f44336;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.border {
  margin: auto;
  border: 1px solid #f44336;
  padding: 10px;
  border-radius: 4px;
}
.modal-body {
  margin: 10px;
  font-size: 1rem;
  line-height: 1.7;
}
.modal-footer {
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  .modal-default-button {
    font-weight: bold;
    letter-spacing: 0.0892857143em;
    padding: 7px 10px;
    margin: 10px 20px;
    align-self: flex-end;
    transition: all 0.3s ease;
    border-radius: 3px;
    background: lightgray;
    color: #000;
    &:hover {
      background: gray;
    }
  }
}
.modal-enter {
  opacity: 0;
  .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.modal-leave-active {
  opacity: 0;
  .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
